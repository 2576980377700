/* normalise styles */
:root {
    --text-highlight-color: #2B55AD;
    --btn-highlight-color: #2B55AD;
    --primary-bg-color: #ECEEF0;
    --secondary-bg-color: #E7E9EB;
    --navbar-bg-color: #DFE4E8;
    --primary-footer-bg-color: #DFE4E8;
    --secondary-footer-bg-color: #B6C1D5;
    --section-bg-color: #F9F9F9;

    --project-card-bg-color: #FFF;
}

body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 200;
    background-color: var(--primary-bg-color);
}

h1 {
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: var(--text-highlight-color);
    text-align: center;
}

h2 {
    font-weight: 300;
}

strong {
    color: var(--text-highlight-color);
}

p {
    text-align: justify;
    text-justify: inter-word;
}